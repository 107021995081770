import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getReceiptPdf } from '../api/webshop';
import logo from '../assets/logo.png';
import LoadingView from './LoadingView';

const DownLoadReceiptView = () => {
  const route = useRouteMatch();
  const { receiptId } = route.params;
  const { companyId } = route.params;
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();

  const getPdf = useCallback(async () => {
    const data = await getReceiptPdf(companyId, receiptId);
    return data;
  }, [companyId, receiptId]);
  const downloadFile = () => {
    const file = new Blob([new Uint8Array(pdfData.data).buffer], {
      type: 'application/pdf',
    });

    const fileURL = URL.createObjectURL(file);

    window.open(fileURL);
  };

  useEffect(() => {
    if (companyId && receiptId && !pdfData && !errorMessage) {
      setLoading(true);
      getPdf()
        .then((data) => {
          setPdfData(data.pdfData);
        })
        .catch(async (e) => {
          if (!e) {
            setErrorMessage(t('error.anErrorOccurred'));
          } else {
            const error = await e.json();
            if (error && error.statusCode === 400) {
              setErrorMessage(error.name);
            } else {
              setErrorMessage(t('error.anErrorOccurred'));
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [companyId, errorMessage, getPdf, pdfData, receiptId, t]);

  if (loading) {
    return <LoadingView text={t('common.loading')} />;
  }

  return (
    <div className="d-flex justify-content-center w-100 h-100">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src={logo} className="rounded" alt="applogo" width="40%" />
        {pdfData && (
          <button
            type="button"
            className="btn btn-block btn-primary mt-3 w-50"
            onClick={() => downloadFile()}
          >
            {t('payment.downloadReceipt')}
          </button>
        )}
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );
};
export default DownLoadReceiptView;
